import React, { useState } from 'react'
import Payment from './Payment';

function Donation() {

  const [showModal, setModel] = useState(false);

const closeModel = ()=>setModel(false);

const [amountinput, setamountinput] = useState({a:""});
// const [amountinput1, setamountinput1] = useState(100);
// const [amountinput2, setamountinput2] = useState(500);
// const [amountinput3, setamountinput3] = useState(1000);
// const [amountinput4, setamountinput4] = useState(10000);

// setamountinput1(2)
// setamountinput2(2)
// setamountinput3(2)
// setamountinput4(2)

const inputamount =(e)=>{
  const {name,value} = e.target; 
  setamountinput({...amountinput, [name]:value})
}

console.log(amountinput.a)

  return (
    <>
      

    <div className="donationboxcontainer">
    <div className="donationboxs">
    
    <div className="donationbox2">
   
    <div className="donationpaymentboxs1">
    <div className="donationpaymentbox1">
    <p>Please Select</p>
    <h2>AMOUNT TO DONATE</h2>
    <p>All donations are tax deductable.</p>
    </div>
    <div className="donationinput">
    <p>&#8377;</p>
    <input type="text" name="a" value={amountinput.a} onChange={inputamount} />
    </div>

    </div>

    <div className="donationpaymentboxs2">

    <div onClick={()=>setModel(true)}  className="donationpaymentbox3">
    <p>&#8377;100</p>
    </div>
    <div   onClick={()=>setModel(true)}  className="donationpaymentbox3">
    <p>&#8377;500</p>
    </div>
    <div onClick={()=>setModel(true)}  className="donationpaymentbox3">
    <p>&#8377;1000</p>
    </div>
    <div onClick={()=>setModel(true)}  className="donationpaymentbox3">
    <p>&#8377;10000</p>
    </div>
    
    <div className="donationpaymentbox3" >
    <p>Custom </p>
    </div>
    
    </div>


    <div className="donationpaymentbutton">
    
    <button onClick={()=>setModel(true)}><a  >Donate Now </a> </button>
        {showModal && <Payment close ={closeModel} inputvalue ={amountinput.a}  />}
        
        
        </div>

   

    </div>
    <div className="donationbox1">
    <h2>We Need Donations</h2>
    <h5>Alone we can do  so little Together we cam do extraordinary.</h5>

    <div className="homesec4rasegoal">
    <p>Raised:</p>
    <p>Goal: </p>
    </div>

    <div className="homesec4donations">
    <div class="containers">
    <div class="skills html">83%</div>
  </div>
    </div>

   

    </div>
    </div>
    
    </div>




    </>
  )
}

export default Donation
