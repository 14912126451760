import React, { useEffect } from 'react'

function Payment({close,inputvalue, value1,value2,value3,value4,}) {



  function onclick(){
    var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  if (dots.style.display === "none") {
    dots.style.display = "inline";
    btnText.innerHTML = "Read more";
    moreText.style.display = "none";
  } else {
    dots.style.display = "none";
    btnText.innerHTML = "Read less";
    moreText.style.display = "inline";
  }
} 

  useEffect(()=>{
    document.body.style.overflowY="hidden";
    return()=>{
      document.body.style.overflowY="scroll";
    };
  },[])
  return (
    <>


    <div className="modalcontainer"></div>

    <div className="modelcontent">
    <div className="paymentupperpart">
    <div className="payementvalue">
    <p>&#8377;</p>
    </div>
    <div className="paymentinputpart">
    <input type="text" value={inputvalue} />
    </div>
    
    </div>


    <div className="paymentupperpart2">
    <div className="payementboxs">
    <p>&#8377; {value1}</p>
    </div>
    <div className="payementboxs">
    <p>&#8377; {value2}</p>
    </div>
    <div className="payementboxs">
    <p>&#8377; {value3}</p>
    </div>
    <div className="payementboxs">
    <p>&#8377;{value4}</p>
    </div>
    <div className="payementboxs" id='payementboxs'>
    <p>custom <br /> amount</p>
    </div>
   
    
    </div>


    <div className="paymentupperpart3">
    <p>Select Payment Method</p>

    <div className="paymentmethodlinks">
    <p><a href="/"><i class="zmdi zmdi-circle-o"></i> Test Donation</a></p>
    <p><a href="/"><i class="zmdi zmdi-circle-o"></i> Offline Donation</a></p>
    <p><a href="/"><i class="zmdi zmdi-circle-o"></i> Stripe - Credit Card</a></p>
    </div>
    
    </div>

    <div className="paymentmidform">
    <p>Personal Info</p>
    <form>

    <div className="paymentformde">
    <div className="paymentforminner">
    <div className="paymentlable1">
    <label htmlFor="name">First Name*</label>
    </div>
    <div className="paymentinput1">
    <input type="text" placeholder=' First Name' />
    </div>
    </div>
    <div className="paymentforminner">
    <div className="paymentlable1">
    <label htmlFor="name">Second Name*</label>
    </div>
    <div className="paymentinput1">
    <input type="text" placeholder=' Second Name' />
    </div>
    </div>
    </div>

    <div className="paymentemail">
    <div className="paymentlable1"> 
    <label htmlFor="email">Email Address * </label>
    </div>
    <div className="paymentemailinput">
    <input type="text" placeholder='Email Address' />
    </div>
    </div>
    
    </form>
    </div>


    <div className="termsmenu">
    
    <p><span id="dots"></span><span id="more">erisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</span></p>
    </div>
    <div className="paymentcheckbox">
    
    <div className="paymentcheckinpunt">
    <input type="checkbox" />
    <p>Agree To Terms?</p>
    </div>
    <div   onClick={onclick} id="myBtn"  className="paymentterms">
    <p  onClick={onclick} id="myBtn" >Show Terms</p>
    </div>
    
    </div>

    <div className="paymentdonationtotal">
    <p>Total Donation</p>
    <input type="text" value={inputvalue}  />
    </div>


<div id="paymentdonatebutton1">
<button>Donate now</button>
</div>

<div className="closebutton">
<p onClick={close}><i class="zmdi zmdi-close-circle"></i></p>
</div>
      
    </div>
      
    </>
  )
}

export default Payment 
