import React, { useState } from 'react'
import welcome from './img/about.jpg'
import about2 from './img/ab1.jpg'
import about4 from './img/ab2.jpg'
import Payment from './Payment'
import { Helmet } from 'react-helmet'

function About() {
  function onclick() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }



  const [showModal, setModel] = useState(false);

  const closeModel = () => setModel(false);

  return (
    <>

      <Helmet>
        <title>Transforming Lives: MGJSS NGO - Empowering Through Culture, Education, Elderly Care, and
        Humanitarian Support</title>
        <meta
          name="description"
          content="Learn about the mission, vision, and impact of Maa Gayatri Jan Seva NGO. Discover how we empower communities and make a positive change."

        />
        <link rel="canonical" href="/about" />
      </Helmet>

      <div className="aboutcontainer">

        <div className="aboutuppersection">

          <div className="pageupperheading1">
            <h1>About Us</h1>
          </div>

          <div className="aboutuppersec1boxs">
            <div className="aboutuppersec1box">
              <div className="aboutimgbox3">

                <img src={about2} alt="Prostheses" />
              </div>


            </div>
            <div className="aboutuppersec1box">
              <h1>" The best way to find your self is to lose yourself in the service of others" <br /> <br /> "Services to other is the rent you pay for your room here on earth" </h1>
              <br />

              <div className="homebutton2">
                <button onClick={() => setModel(true)}>Donate Now</button>
                {showModal && <Payment close={closeModel} />}
              </div>
            </div>
            <div className="aboutuppersec1box">
              <div className="aboutimgbox3">

                <img src={about4} alt="maa gaytri jan sewa sansthan" />
              </div>
            </div>

          </div>

        </div>



        <div className="aboutsec1container">

          <div className="aboutsec1boxs">

            <div className="aboutsecbox">

              <div className="aboutimgbox1">
                <img src={welcome} alt="Education to poor child" />
                <div className="imghoverbutton1">
                  <button onClick={() => setModel(true)}>Donate Now</button>
                  {showModal && <Payment close={closeModel} />}
                </div>
              </div>
            </div>
            <div className="aboutsecbox   aboutbox12">
              <h1>Wellcome to our Charity</h1>


              <p>Our organization is focused on a wide range of social causes, including helping orphans and the elderly, blood donation, police welfare, environmental awareness, assistance for the marriage of poor girls, awareness and rescue for domestic violence, and providing free education and skill development for underprivileged girls and children.<span id="dots">...</span> <br /> <br /> <span id="more"> At Maa Gayatri Jan Sewa Sansthan, we firmly believe that every person deserves the opportunity to lead a healthy, fulfilling life, regardless of their circumstances. Our mission is to provide a helping hand to those in need, and to empower them to become self-sufficient and independent. <br /> <br />
                We have a team of dedicated volunteers who work tirelessly to ensure that our programs are implemented effectively and efficiently. Our programs are designed to provide tangible benefits to the communities we serve, and we are constantly striving to improve and expand our services. <br /> <br />
                Our organization is funded entirely through donations, and we rely on the generosity of our supporters to continue our work. If you are interested in contributing to our cause, please visit our website to learn more about how you can help. <br /> <br />
                Thank you for your interest in Maa Gayatri Jan Sewa Sansthan. Together, we can make a positive impact on the lives of those in need.
              </span></p>





              <button onClick={onclick} id="myBtn">Read more</button>
            </div>

          </div>


        </div>

        <div className="aboutourmisssionsection">

          <div className="aboutmissionboxs">

            <div className="aboutmissionbox">
              <h1>Our Mission</h1>
              <p>Our mission at Maa Gayatri Jan Sewa Sansthan is to serve and empower the most vulnerable sections of society, with a particular focus on women, children, and the elderly. We are committed to providing access to basic necessities, education, and skill development opportunities, and promoting awareness and advocacy for issues that affect these communities.</p>
            </div>
            <div className="aboutmissionbox">
              <h1>Our Vision</h1>
              <p>Our vision is to create a world where every individual has equal access to opportunities and resources, regardless of their background or circumstances. We envision a society where women, children, and the elderly are empowered to lead healthy, fulfilling lives, and where everyone is committed to creating a better future for the next generation. At Maa Gayatri Jan Sewa Sansthan, we strive to be a catalyst for positive change and a beacon of hope for those who need it the most.</p>
            </div>
          </div>

          <div className="aboutmissionbox1">
            <h1>Our Aim</h1>
            <p>We aim to address the root causes of poverty and promote sustainable development by working with communities to identify their specific needs and priorities. Our programs focus on education, healthcare, food security, shelter, and other basic needs, as well as empowering women and girls through education, leadership training, and economic opportunities.</p>
          </div>

        </div>









        <div className="section5boxs">

          <div className="section6mid">
            <section className="pt-5 pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">

                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="row">


                            <div className="col-md-12 mb-0 box">
                              <div className="card aboutcards">
                                <div className="aboutcardcontent">
                                  <h1>Necessitatibus Dolorum Scelerisque Officiis Dignissimos Eveniet Maiores Quis.</h1>
                                  <p> You can use a few enticing words and flaunt your capabilities that will attract future donors and encourage them to donate right away.
                                  </p>
                                </div>


                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="row">


                            <div className="col-md-12 mb-0 box">
                              <div className="card aboutcards">
                                <div className="aboutcardcontent">
                                  <h1>Necessitatibus Dolorum Scelerisque Officiis Dignissimos Eveniet Maiores Quis.</h1>
                                  <p> You can use a few enticing words and flaunt your capabilities that will attract future donors and encourage them to donate right away.
                                  </p>
                                </div>


                              </div>
                            </div>




                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-6 text-right  arro">
                    <a className="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                      <i className="fa fa-arrow-left"></i>
                    </a>
                    <a className="btn btn-primary mb-3 " href="#carouselExampleIndicators2" role="button" data-slide="next">
                      <i className="fa fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </section>



          </div>

        </div>









      </div>





    </>
  )
}

export default About
