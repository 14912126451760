import React from 'react'

import img1 from './img/meriyatra.jpg'
import img2 from './img/lucknow1.jpg'
import img3 from './img/jansakti.jpg'
import img4 from './img/narisakti1.jpg'

function HomeOurProject() {
  return (
    <>

    <div className="homeourprojectcontainer">
    
    <div className="homeprojectcontent">
    <h1>Our Projects </h1>
    <p>You can use a few enticing words and flaunt your capabilities that will attract future <br /> donors and encourage them to donate right away.</p>
    </div>
    <div className="homeprojectimgboxs">
    <div className="homeprojectimgbox">
    <div className="homeprojectimg">
    <img src={img1} alt="Maa Gayatri ngo Meri Yatra " />
    </div>
    <h2>Meri Yatra​</h2>
    </div>
    <div className="homeprojectimgbox">
    <div className="homeprojectimg">
    <img src={img2} alt="Lucknow Hastshilp Mahotsav" />
    </div>
    <h2>Lucknow Hastshilp Mahotsav ​</h2>
    </div>
    <div className="homeprojectimgbox">
    <div className="homeprojectimg">
    <img src={img3} alt="Maa Gayatri Janshakti Sewa samman " />
    </div>
    <h2>Janshakti Sewa samman​</h2>
    </div>
    <div className="homeprojectimgbox">
    <div className="homeprojectimg">
    <img src={img4} alt="Maa Gayatri Nari Shaktikaran Samman " />
    </div>
    <h2>Nari Shaktikaran Samman </h2>
    </div>
    
   
    </div>
    
    </div>
    
    </>
  )
}

export default HomeOurProject
