import React from 'react'
import Home from './Home'
import HomeOurMission from './HomeOurMission'
import HomeOurProject from './HomeOurProject'
import Donation from './Donation'
import { Helmet } from 'react-helmet'

function Home1() {
  return (
    <>

    <Helmet>
    <title>Empowering Communities: MGJSS NGO - Literary, Cultural, Elderly Care, Education, Blood Donation,
    Environment, Food More </title>
    <meta
      name="description"
      content="Discover the transformative work of Maa Gayatri Jan Seva NGO. Empowering communities, providing support, and making a difference in people's lives."
     
    />
    <link rel="canonical" href="/" />
    </Helmet>


    <Home/>
    <HomeOurMission/>
    <HomeOurProject/>
    <Donation/>
      
    </>
  )
}

export default Home1
