import React, { useState } from 'react'
// import con3 from './img/con1.jpg'
// import con4 from './img/con3.jpg'
import co1 from './img/co1.jpg'
import co2 from './img/cu23.jpg'
import card1 from './img/card11.png'
import card2 from './img/card21.jpg'
import card3 from './img/card31.jpg'
import card4 from './img/education1.jpg'
import card5 from './img/b.jpg'
import card6 from './img/card61.jpg'
import card7 from './img/card7.jpg'
import card8 from './img/cause1.jpg'
import card9 from './img/card9.jpg'
import Payment from './Payment'
import { Helmet } from 'react-helmet'

function OurCauses() {


  const [showModal, setModel] = useState(false);

  const closeModel = () => setModel(false);

  return (
    <> 
    <Helmet>
    <title>Catalyzing Change: MGJSS NGO - Advancing Literacy, Elderly Care, Education, Blood Donation, and
    Social Welfare</title>
    <meta
      name="description"
      content="Explore the impactful causes supported by Maa Gayatri Jan Seva NGO. Join us in creating a better world through our dedicated initiatives."

    />
    <link rel="canonical" href="/about" />
  </Helmet>



      <div className="causescontainer">




        <div className="aboutuppersection">
          <div className="pageupperheading1">
            <h1>Our Causes</h1>
          </div>

          <div className="aboutuppersec1boxs">
            <div className="aboutuppersec1box">
              <div className="aboutimgbox3">

                <img src={co1} alt="Prostheses" />
              </div>


            </div>
            <div className="aboutuppersec1box">
              <h1>"Giving  is not just about nake a donation, it`s about making a difference" <br /> <br /> "We can`t help every one, But everyone can help someone."</h1>
              <br />
              <br />
              <div className="homebutton2">
                <button onClick={() => setModel(true)}>Donate Now</button>
                {showModal && <Payment close={closeModel} />}
              </div>
            </div>
            <div className="aboutuppersec1box">
              <div className="aboutimgbox3">

                <img src={co2} alt="provide some  basic need" />
              </div>
            </div>

          </div>

        </div>



        <div className="causesbox2">

          <div className="causesboxs">
            <div className="causesbox1">
              <h2>Help Us Providing Them Their Basics!</h2>
              <br />
              <p> You can use a few enticing words and flaunt your capabilities that will attract future <br /> donors and encourage them to donate right away.</p>
              <br />
            </div>
            <div className="causescards">

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card1} alt="Literary and Cultural Programs by the organization​" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}

                  </div>
                </div>
                <h3>Literary and Cultural Programs by the organization​</h3>
              </div>

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card2} alt="Old age Service" />
                  <div className="imghoverbutton">

                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}

                  </div>
                </div>
                <h3>Old age Service</h3>
              </div>

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card3} alt="swing embroidery training" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Free swing embroidery training center for underprivileged females​</h3>
              </div>

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card4} alt="Child education" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Child education and development ​​</h3>
              </div>

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card5} alt="Blood donation" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Blood donation for needy peoples </h3>
              </div>

              <div className="causescard">
                <div className="causescardimg">
                  <img src={card6} alt="Protection and awareness" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Protection and awareness for environmen</h3>
              </div>
              <div className="causescard">
                <div className="causescardimg">
                  <img src={card7} alt="img" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Provide food grocery for destitute and helpless families </h3>
              </div>
              <div className="causescard">
                <div className="causescardimg">
                  <img src={card8} alt="poor girls marriage" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3>Donation for poor girls marriage</h3>
              </div>
              <div className="causescard">
                <div className="causescardimg">
                  <img src={card9} alt="Prostheses for the disables" />
                  <div className="imghoverbutton">
                    <button onClick={() => setModel(true)}>Donate Now</button>
                    {showModal && <Payment close={closeModel} />}
                  </div>
                </div>
                <h3> Prostheses for the disables </h3>
              </div>
            </div>

            <br /><br />


          </div>

        </div>

      </div>

    </>
  )
}

export default OurCauses
