import React, { useState } from 'react'
import img1 from './img/Maa Gayatri team1.jpg'
import img2 from './img/Maa Gayatri team2.jpg'
import team1 from './img/team1.jpg'
import team2 from './img/team2.jpg'
import team3 from './img/team3.jpg'
import team4 from './img/team4.jpg'
import team5 from './img/team5.jpg'
import team6 from './img/team6.jpg'
import team7 from './img/team7.jpg'
import team8 from './img/team8.jpg'
import team21 from './img/team21.jpg'
import team22 from './img/team22.jpg'
import team23 from './img/team23.jpg'
import team24 from './img/team24.jpg'
import team25 from './img/team25.jpg'
import team26 from './img/team26.jpg'
import Payment from './Payment'
import { Helmet } from 'react-helmet'


function Team() {


  const [showModal, setModel] = useState(false);

  const closeModel = ()=>setModel(false);

  return (
    <>


    <Helmet>
    <title>Dedicated Dream Makers: MGJSS NGO Team - Empowering Communities through Passion and
    Compassion</title>
    <meta
      name="description"
      content="Meet the exceptional team members of Maa Gayatri Jan Seva NGO. Dedicated individuals working together for community empowerment."

    />
    <link rel="canonical" href="/about" />
  </Helmet>

  <div className="teamcontainer">
              
      <div className="aboutuppersection">
      <div className="pageupperheading1">
      <h1>Our Team</h1>
      </div>
            
      <div className="aboutuppersec1boxs">
      <div className="aboutuppersec1box">
      <div className="aboutimgbox3">
      
      <img src={img1} alt="Maa gayatri team" />
      </div>
    
      
      </div>
      <div className="aboutuppersec1box">
    <h1>" Voluntears do not necessory have the TIME they just have the HEART " <br /> <br />  " There could be no defination of successful life that does not include services to others. " </h1>
    <br />
    <br />
    <div className="homebutton2">
    <button onClick={()=>setModel(true)}>Donate Now</button>
    {showModal && <Payment close ={closeModel}/>}
    </div>
      </div>
      <div className="aboutuppersec1box">
      <div className="aboutimgbox3">
      
      <img src={img2} alt="img" />
      </div> 
      </div>
      
      </div>

      </div>

      <div className="teamcontainer">

      <div className="teamseccontainer">
      <div className="teamheading">
      <h1> मुख्य टीम</h1>
      </div>
      
        <div className="teamsecboxs">
         
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team1} alt="अरूण प्रताप सिंह
              अध्यक्ष- माँ गायत्री जनसेवा संस्थान" />
            </div>
            <div className="teamboxcontent">
              <h3>अरूण प्रताप सिंह </h3>
              <h5>अध्यक्ष- माँ गायत्री जनसेवा संस्थान</h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team4} alt="विनय द्विवेदी
              उपाध्यक्ष- माँ गायत्री जनसेवा संस्थान" />
            </div>
            <div className="teamboxcontent">
            <h3>विनय द्विवेदी</h3>
            <h5>उपाध्यक्ष- माँ गायत्री जनसेवा संस्थान </h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team2} alt="हेमू चौरसिया
              सचिव- माँ गायत्री जनसेवा संस्थान" />
            </div>
            <div className="teamboxcontent">
              <h3>हेमू चौरसिया</h3>
              <h5>सचिव- माँ गायत्री जनसेवा संस्थान</h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team3} alt="संदीप शुक्ला
              कोषाध्यक्ष- माँ गायत्री जनसेवा संस्थान" />
            </div>
            <div className="teamboxcontent">
            <h3>संदीप शुक्ला </h3>
            <h5>कोषाध्यक्ष- माँ गायत्री जनसेवा संस्थान</h5>
            </div>

          </div>
          

          <div className="teamsecbox">
          <div className="teamboximg">
            <img src={team5} alt="प्रदीप शुक्ला
            राष्ट्रीय प्रवक्ता" />
          </div>
          <div className="teamboxcontent">
            <h3>प्रदीप शुक्ला</h3>
            <h5>राष्ट्रीय प्रवक्ता</h5>
          </div>

        </div>

          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team6} alt="मुकेश मिश्रा
              मीडिया प्रभारी" />
            </div>
            <div className="teamboxcontent">
            <h3>मुकेश मिश्रा</h3>
            <h5>मीडिया प्रभारी </h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team7} alt="अमित तिवारी
              प्रदेश प्रभारी" />
            </div>
            <div className="teamboxcontent">
            <h3>अमित तिवारी </h3>
            <h5>प्रदेश प्रभारी </h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team8} alt="बद्री विशाल पाण्डेय
              प्रदेश सह प्रभारी" />
            </div>
            <div className="teamboxcontent">
            <h3>बद्री विशाल पाण्डेय</h3>
            <h5>प्रदेश  सह प्रभारी </h5>
            </div>

          </div>
         

        </div>

      </div>





      <div className="teamseccontainer">
      <div className="teamheading">
      <h1> संरक्षक मंडल</h1>
      </div>
      
        <div className="teamsecboxs">
         
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team21} alt="श्री पवन सिंह
              एमएलसी सीतापुर अध्यक्ष एस.आर.ग्रुप्स ऑफ इंस्टिट्यूट" />
            </div>
            <div className="teamboxcontent">
              <h3>श्री पवन सिंह</h3>
              <h5>एमएलसी सीतापुर अध्यक्ष एस.आर.ग्रुप्स ऑफ इंस्टिट्यूट  </h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team22} alt="श्री आरके चतुर्वेदी जी
              पूर्व आईजी उत्तर प्रदेश सदस्य पुलिस भर्ती बोर्ड" />
            </div>
            <div className="teamboxcontent">
              <h3>श्री आरके चतुर्वेदी जी</h3>
              <h5> पूर्व आईजी उत्तर प्रदेश  सदस्य पुलिस भर्ती बोर्ड  </h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team23} alt="श्री मुकेश बहादुर सिंह
              चेयरमैन इंडो अमेरिकन चैंबर ऑफ कॉमर्स" />
            </div>
            <div className="teamboxcontent">
            <h3>श्री मुकेश बहादुर सिंह</h3>
            <h5>चेयरमैन इंडो अमेरिकन चैंबर ऑफ कॉमर्स</h5>
            </div>

          </div>
          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team24} alt="श्री संतोष श्रीवास्तव
              डायरेक्टर नीलांश ग्रुप" />
            </div>
           
            <div className="teamboxcontent">
            <h3>श्री संतोष श्रीवास्तव</h3>
            <h5>डायरेक्टर नीलांश ग्रुप</h5>
            </div>

          </div>

          <div className="teamsecbox">
          <div className="teamboximg">
            <img src={team25} alt="श्री राजेश्वर सिंह
            विधायक सरोजनीनगर लखनऊ" />
          </div>
          <div className="teamboxcontent">
          <h3>श्री राजेश्वर सिंह </h3>
          <h5>विधायक सरोजनीनगर लखनऊ</h5>
            
          </div>

        </div>

          <div className="teamsecbox">
            <div className="teamboximg">
              <img src={team26} alt="श्री समीर शेख
              सीएमडी ड्रीम्ज ग्रुप" />
            </div>
            <div className="teamboxcontent">
            <h3>श्री समीर शेख</h3>
            <h5>सीएमडी ड्रीम्ज ग्रुप</h5>
            </div>

          </div>
          
         
         

        </div>

      </div>











    </div>



      </div>
    </>
  )
}

export default Team
