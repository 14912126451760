import React from 'react'

import logo from './img/logo.jpg'
import { NavLink } from 'react-router-dom'

function Navbar({openSidebar}) {
  return (
    <div>
      <div className="navbarcontainer">
      <div className="navbarboxs">
      <div className="navbarbox">
      <div className="navbarimgbox">
      <img src={logo} alt="logo" />
      <div className="navlogobackground">
      </div>
      </div>
      
      </div>
      <div className="navbarbox">
      <ul>
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="/about">About</NavLink></li>
      <li><NavLink to="/causes">Our Causes</NavLink></li>
      <li><NavLink to="/team">team</NavLink></li>
      <li><NavLink to="/gallery">Gallery</NavLink></li>
      <li><NavLink to="/contact">Contact Us</NavLink></li>
      </ul>
      </div>
      <div onClick={openSidebar} className="menubutton">
      <i class="zmdi zmdi-menu"></i>
      </div>
      </div>
      
      </div>
    </div>
  )
}

export default Navbar
