import React  from 'react'

import home1 from './img/logo2.png'

import { NavLink } from 'react-router-dom'

function Home() {


  return (
    <>
      <div className="homecontaner">
      <div className="homeboxs">
      <div className="homebox">
      <h2>Welcome to Maa Gayatri Jan Sewa Sansthan</h2>
      <p>Our organization is focused on a wide range of social causes, including helping orphans and the elderly, blood donation, police welfare, environmental awareness, assistance for the marriage of poor girls, awareness and rescue for domestic violence, and providing free education and skill development for underprivileged girls and children.</p>
      <div className="homebutton">
      <button > <NavLink to="/about">  Read More </NavLink></button>
      
      </div>
      </div>
      <div className="homebox1">
     <img src={home1} alt="Maa Gayatri Jan " />
      </div>

      <div className="homebox">
      <h1>Together We  Can  Make  Difference!</h1>
      </div>
      </div>


      
      </div>
    </>
  )
}

export default Home
